.footer {
  background-color: #252a33 !important;
  color: #ddd;
}
.footer {
  padding: 30px 0;
  background: #f8f9fe;
}
.list-inline,
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
dl,
ol,
ul {
  margin-top: 0;
}
hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgb(21 24 29);
}
.text-end {
  text-align: right !important;
}
.btn-group-sm > .btn,
.btn-sm {
  font-size: 0.75rem;
}
.btn:not(:last-child) {
  margin-right: 0.5rem;
}
.text-light {
  color: #ced4da !important;
}
.btn-link {
  font-weight: 600;
}
