.vaults-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 500px);
}

.font-bold {
  font-weight: bold;
}

.font-bolder-input {
  font-size: 16px;
  font-weight: 600;
}
.flex-vaults {
  flex-direction: row;
}
@media (max-width: 991.98px) {
  .flex-vaults {
    flex-direction: column;
  }
}

