.input-group-text {
  background-color: #292e38;
  border: 1px solid #292e38;
  color: white;
}
.form-control:disabled,
.form-control[readonly] {
  opacity: 1;
  background-color: #292e38;
  border-color: #292e38;
  color: #969696;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  min-width: 0;
  margin-bottom: 0;
  flex: 1 1;
}
.form-control {
  font-size: 0.875rem;
  height: calc(1.5em + 1.25rem + 6px);
  transition: all 0.15s ease-in-out;
}
.btn-success,
.btn-success:hover {
  color: #fff;
  border-color: #2dce89;
  background-color: #2dce89;
}

.btn-white,
.btn-white:hover {
  color: #182634;
  border-color: #15181d;
  background-color: #fff;
}