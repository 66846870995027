.successTR {
  position: absolute;
  top: 0px;
  right: 10px;
  background-color: green;
  text-align: left;
  width: auto !important;
  z-index: 9151 !important;
}
.errorTR {
  position: absolute;
  top: 0;
  right: 10px;
  background-color: red;
  text-align: left;
  width: auto !important;
  z-index: 9151 !important;
}
.successTR .swal-text:last-child {
  margin-bottom: 10px !important;
}
.errorTR .swal-text:last-child {
  margin-bottom: 10px !important;
}
.successTR .swal-text:first-child {
  margin-top: 10px !important;
}
.errorTR .swal-text:first-child {
  margin-top: 10px !important;
}
.successTR .swal-text {
  color: white !important;
}
.errorTR .swal-text {
  color: white !important;
}

.successTR .swal-modal {
  width: auto !important;
}
.errorTR .swal-modal {
  width: auto !important;
}

.successTR .swal-text {
  font-size: 12px !important;
  max-width: 100% !important;
}
.errorTR .swal-text {
  font-size: 12px !important;
  max-width: 100% !important;
}
